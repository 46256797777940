<template>
  <div>
    <CModal color="warning" :show="shows" size="lg" v-if="data.product">
      <template v-slot:header>
        <h5 class="modal-title font-weight-bold">Check Stock</h5>
        <button
          type="button"
          aria-label="Close"
          class="close text-white"
          @click="closeModal()"
        >
          ×
        </button>
      </template>
      <div class="text-center" v-if="data.product">
        <img
          height="150px"
          width="150px"
          class="rounded mx-auto d-block"
          style="object-fit: cover;"
          :src="data.product ? data.product.image : null"
        />
      </div>
      <h4 class="text-center mt-3">{{ data.product.name }}</h4>
      <p v-if="data.product" class="text-center mt-3">
        <CIcon name="ProductModel" />
        <span class="badge badge-light"
          >{{ data.product.product_model.name }}
        </span>
        &#8594;
        <CIcon name="ProductCategory" />
        <span class="badge badge-light"
          >{{ data.product.product_category.name }}
        </span>
        &#8594; <CIcon name="Brand" />
        <span class="badge badge-light">{{ data.product.brand.name }} </span>
        &#8594; <CIcon name="ProductGroup" />
        <span class="badge badge-light"
          >{{ data.product.product_group.name }}
        </span>
      </p>
      <div class="row">
        <div class="card col-md-6 rounded-0">
          <div class="card-body">
            <h4 class="text-muted text-center font-weight-bold">Stock</h4>
            <h3 class="text-center font-weight-bold">{{ data.stocks }}</h3>
          </div>
        </div>
        <div class="card col-md-6 rounded-0">
          <div class="card-body">
            <h4 class="text-muted text-center font-weight-bold">
              Purchase Price
            </h4>
            <h3 class="text-center font-weight-bold">
              {{ data.purchase_price.toLocaleString("en") }}
            </h3>
          </div>
        </div>
      </div>
      <div class="row" v-if="data.purchasing_codes">
        <div class="col-md-12">
          Purchasing codes :
          <span
            v-for="code in data.purchasing_codes"
            class="text-muted"
            :key="code"
          >
            {{ code }},
          </span>
        </div>
      </div>
      <template v-slot:footer>
        <CButton color="secondary" @click="closeModal">Close</CButton>
        <CButton color="success" @click="save" v-if="!isSaving"
          >Yes, Submit!</CButton
        >
        <CButton color="secondary" class="px-4" disabled v-else>
          <CSpinner grow size="sm" /> Saving..
        </CButton>
      </template>
    </CModal>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "StockConfirmationModal",
  props: ["data", "show"],
  computed: {
    ...mapState({
      isSaving: (state) => state.stocks.isSaving,
    }),
    shows() {
      return this.show;
    },
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    save() {
      this.$store.dispatch("stocks/saveStock", this.data);
    },
  },
};
</script>
